@media (width >= 640px) {
  .grid-container {
    grid-gap: 10px;
    grid-template-columns: 1fr 1fr;
    margin: auto;
    display: grid;
  }

  .grid-container .grid-item {
    justify-content: center;
    align-items: center;
    min-width: 0;
    display: flex;
    position: relative;
  }

  .grid-container .grid-item:first-child {
    aspect-ratio: 1 / .6;
    grid-column: 1 / 3;
  }

  .grid-container .grid-item:nth-child(2), .grid-container .grid-item:nth-child(3) {
    aspect-ratio: 1 / .78;
  }

  .grid-container .grid-item:nth-child(4) {
    grid-area: 2 / 2 / 4 / 3;
  }

  .grid-container .grid-item:nth-child(5), .grid-container .grid-item:nth-child(6) {
    aspect-ratio: 1 / .78;
  }
}

@media (width <= 640px) {
  .grid-container {
    grid-gap: 10px;
    grid-template-columns: 1fr;
    margin: auto;
    display: grid;
  }

  .grid-container .grid-item {
    justify-content: center;
    align-items: center;
    min-width: 0;
    display: flex;
  }

  .grid-container .grid-item:first-child, .grid-container .grid-item:nth-child(2), .grid-container .grid-item:nth-child(3), .grid-container .grid-item:nth-child(4), .grid-container .grid-item:nth-child(5), .grid-container .grid-item:nth-child(6) {
    aspect-ratio: 1 / .78;
  }
}

/*# sourceMappingURL=index.48f5d380.css.map */
