@media (min-width: 640px) {
  .grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
    margin: auto;


    .grid-item {
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 0;
      position: relative;

      &:nth-child(1) {
        aspect-ratio: 1 / 0.6;
        grid-column: 1/3;
      }

      &:nth-child(2) {
        aspect-ratio: 1 / 0.78;
      }

      &:nth-child(3) {
        aspect-ratio: 1 / 0.78;
      }

      &:nth-child(4) {
        grid-row: 2 / 4;
        grid-column: 2/3;
      }

      &:nth-child(5) {
        aspect-ratio: 1 / 0.78;
      }

      &:nth-child(6) {
        aspect-ratio: 1 / 0.78;
      }

    }
  }
}

@media (max-width: 640px) {
  .grid-container {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 10px;
    margin: auto;


    .grid-item {
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 0;

      &:nth-child(1) {
        aspect-ratio: 1 / 0.78;
      }

      &:nth-child(2) {
        aspect-ratio: 1 / 0.78;
      }

      &:nth-child(3) {
        aspect-ratio: 1 / 0.78;

      }

      &:nth-child(4) {
        aspect-ratio: 1 / 0.78;
      }

      &:nth-child(5) {
        aspect-ratio: 1 / 0.78;
      }

      &:nth-child(6) {
        aspect-ratio: 1 / 0.78;
      }
    }
  }
}
